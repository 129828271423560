import { apiGet, apiPost } from './service'

export async function listCustomer(data) {
	return await apiPost("/api/Customer/list", data);
}

export async function fullListCustomer(data) {
	return await apiPost("/api/Customer/fulllist", data);
}

export function getCustomer(data) {
	let url = "/api/Customer/read/" + data;
	return apiGet(url, {params: {timestamp: Math.random()}});
}

export function postCustomer(data) {
	return apiPost("/api/Customer/edit", data);
}

export function addCustomer(data) {
	return apiPost("/api/Customer/add", data);
}

export function deleteCustomer(data) {
	return apiGet("/api/Customer/delete/" + data);
}
