<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()" :disabled="isSaving" type="button" class="btn btn-info mt-3">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right" to="/admin/Customer/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="id" class="col-sm-2 col-form-label">編號</label>
							<div class="col-sm-10">
								<input v-model="model.id" type="text" class="form-control" id="id" name="id" readonly />
							</div>
						</div>
						<div class="card">
							<div class="card-body">
								<div class="form-group row">
									<label htmlFor="name" class="col-sm-2 col-form-label">設置者</label>
									<div class="col-sm-10">
										<input v-model="model.name" type="text" class="form-control" id="name"
											name="name" required />
									</div>
								</div>
								<div class="form-group row">
									<label htmlFor="idcard" class="col-sm-2 col-form-label">統邊/身份證字號</label>
									<div class="col-sm-10">
										<input v-model="model.idcard" type="text" class="form-control" id="idcard"
											name="idcard" />
									</div>
								</div>

								<div class="form-group row">
									<label htmlFor="sex" class="col-sm-2 col-form-label">性別</label>
									<div class="col-sm-5">
												<select v-model="model.sex" class="form-control" id="sex" 
													name="sex">
													<option value="M">男</option>
													<option value="F">女</option>
													<option value="">未知</option>
												</select>
											</div>
								</div>
								<div class="form-group row">
									<label htmlFor="phone" class="col-sm-2 col-form-label">聯絡電話</label>
									<div class="col-sm-10">
										<input v-model="model.phone" type="text" class="form-control" id="phone"
											name="phone" />
									</div>
								</div>
								<div class="form-group row">
									<label htmlFor="email" class="col-sm-2 col-form-label">電子信箱</label>
									<div class="col-sm-10">
										<input v-model="model.email" type="text" class="form-control" id="email"
											name="email" />
									</div>
								</div>
								<div class="form-group row">
									<label htmlFor="address" class="col-sm-2 col-form-label">設籍地址</label>
									<div class="col-sm-10">
										<input v-model="model.address" type="text" class="form-control" id="address"
											name="address" />
									</div>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-body">
								<div class="form-group row">
									<label htmlFor="representative" class="col-sm-2 col-form-label">負責人姓名</label>
									<div class="col-sm-10">
										<input v-model="model.representative" type="text" class="form-control"
											id="representative" name="representative" />
									</div>
								</div>
								<div class="form-group row">
									<label htmlFor="rpt_sex" class="col-sm-2 col-form-label">負責人性別</label>
									<div class="col-sm-5">
												<select v-model="model.rpt_sex" class="form-control" id="rpt_sex" 
													name="rpt_sex">
													<option value="M">男</option>
													<option value="F">女</option>
													<option value="">未知</option>
												</select>
											</div>
								</div>
								<div class="form-group row">
									<label htmlFor="rpt_idcard" class="col-sm-2 col-form-label">負責人身份證</label>
									<div class="col-sm-10">
										<input v-model="model.rpt_idcard" type="text" class="form-control"
											id="rpt_idcard" name="rpt_idcard" />
									</div>
								</div>
								<div class="form-group row">
									<label htmlFor="rpt_phone" class="col-sm-2 col-form-label">負責人電話</label>
									<div class="col-sm-10">
										<input v-model="model.rpt_phone" type="text" class="form-control" id="rpt_phone"
											name="rpt_phone" />
									</div>
								</div>
								<div class="form-group row">
									<label htmlFor="rpt_email" class="col-sm-2 col-form-label">負責人信箱</label>
									<div class="col-sm-10">
										<input v-model="model.rpt_email" type="text" class="form-control" id="rpt_email"
											name="rpt_email" />
									</div>
								</div>
								<div class="form-group row">
									<label htmlFor="rpt_addr" class="col-sm-2 col-form-label">負責人地址</label>
									<div class="col-sm-10">
										<input v-model="model.rpt_addr" type="text" class="form-control" id="rpt_addr"
											name="rpt_addr" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
import LayoutDiv from '@/components/LayoutDiv.vue'
import { addCustomer } from '@/api/Customer.js'

export default {
	name: 'ProjectCreate',
	components: {
		LayoutDiv
	},
	data() {
		return {
			model: {
				id: '',
				name: '',
				idcard: '',
				sex: '',
				phone: '',
				email: '',
				address: '',
				representative: '',
				rpt_sex: '',
				rpt_idcard: '',
				rpt_phone: '',
				rpt_email: '',
				rpt_addr: '',

			},
			isSaving: false,
		};
	},
	methods: {
		handleSave() {
			this.isSaving = true

			addCustomer(this.model).then(rep => {

				this.isSaving = false;
				this.reset();
				return rep;
			})
				.catch(error => {
					this.isSaving = false
					return error
				});
		},
		reset() {
			this.model.id = "";
			this.model.name = "";
			this.model.idcard = "";
			this.model.sex = "";
			this.model.phone = "";
			this.model.email = "";
			this.model.address = "";
			this.model.representative = "";
			this.model.rpt_sex = "";
			this.model.rpt_idcard = "";
			this.model.rpt_phone = "";
			this.model.rpt_email = "";
			this.model.rpt_addr = "";

		}
	}
};
</script>
